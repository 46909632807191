<template>
  <div class="container">
    <div class="head">
      <div class="nav">
        <div class="logo">
          <img src="../assets/logo.png" alt="logo">
        </div>
        <div class="nav-right">
          <!-- <router-link to="/">主页</router-link>
          <router-link to="/introduce">公司简介</router-link> -->
          <router-link to="/">演员模特</router-link>
        </div>
      </div>
    </div>
    <div class="banner">
      <slider :options="options">
        <slideritem v-for="(banner, i) in banners" :key="i">
          <img :src="banner.media ? banner.media.url : ''" alt="banner">
        </slideritem>
      </slider>
    </div>
    <slot />
    <section v-if="pathName !== '/model'"
             class="section footer-section">
      <div class="title">合作品牌</div>
      <div class="title-es">包括但不限于以下品牌</div>
      <div class="s5-img-list">
        <div v-for="(img, i) in cooperationList" :key="i">
          <img :src="img" alt="">
        </div>
      </div>
    </section>
    <div class="footer">
      <div class="footer-content">
        <div class="footer-logo">
          <img src="../assets/footer-logo.png" alt="">
        </div>
        <div class="footer-txt">
          <p>联系方式：020-89206870</p>
          <p>联系邮箱：andy@xindongcm.com</p>
          <p>联系地址：广州市海珠区中州交易中心北塔710-711</p>
        </div>
        <div class="footer-wechat">
          <img src="../assets/wechat.png" alt="">
          <p>(扫码添加获取更多视频案例)</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getBanners } from "@/api";
import { slider, slideritem } from "vue-concise-slider";

export default {
  name: "index",
  components: {
    slider,
    slideritem
  },
  data () {
    return {
      banners: [],
      pathName: location.pathname,
      bannerKey: 0,
      options: {
        autoplay: 3000,
        loop: true,
        currentPage: 0
      }
    }
  },
  mounted() {
    this.getBanner();
  },
  activated() {
    document.documentElement.scrollTop = 0;
    this.$data.bannerKey += 1;
  },
  methods: {
    async getBanner () {
      const { data } = await getBanners();
      this.$data.banners = data;
    },
  },
  computed: {
    cooperationList () {
      return new Array(30).join().split(',')
          .map((_, i) => require(`../assets/s5-${i+1}.png`))
    }
  }
}
</script>

<style scoped>
@import "./layout.css";
@import "./media.css";
</style>